import React , { useState} from 'react';
import SideMenu from './SideMenu'

export default function Home() {
 
  return (
    <div >
        <SideMenu />
    </div>
  );
}